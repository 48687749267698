<template lang="pug">
  article.service
    figure.service__cover
      img.service__image(
        :src='"."+service.image'
        :alt='service.title'
      )
    .service__body
      h3.service__title(
        :class='titleClass'
      ) {{ service.title }}
      p.service__caption(
        v-if='service.caption'
      ) {{ service.caption }}
      //- .service__price(
      //-   v-html='formattedPrice'
      //- )
      .service__button
        Button(
          type='link'
          :to='serviceUrl'
          :classes="['has-arrow', 'has-area']"
          label="Получить услугу"
        )
</template>

<script>
import { mapGetters } from "vuex";
import Button from "@/components/Button.vue";

export default {
  props: {
    id: [Number, String]
  },
  computed: {
    ...mapGetters({
      serviceItem: "services/item"
    }),
    service() {
      return this.serviceItem(this.id);
    },
    formattedPrice() {
      return this.service.price >= 0
        ? `<b>от</b>${this.service.price}<i>₽</i>`
        : "<span>Бесплатно</span>";
    },
    serviceUrl() {
      return `/service/${this.id}`;
    },
    titleClass() {
      let symbols = this.service.title.split("");
      let className = "";

      if (symbols.length > 60) {
        className = "service__title--small";
      }

      return className;
    }
  },
  components: {
    Button
  }
};
</script>

<style lang="scss">
.service {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  z-index: 0;
  background-color: $white;
  box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.15);
  border-radius: 14px;
  overflow: hidden;
  transition: ease transform 300ms;

  &:hover {
    transform: translateY(-10px);
  }

  &__cover {
    width: 100%;
    height: 220px;
    flex-shrink: 0;
  }

  &__image {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
    padding: 20px;
    position: absolute;
    background-color: rgba(255, 255, 255, 0.9);
    height: 100%;
  }

  &__title {
    flex-grow: 1;
    color: $accent;
    font-weight: $semibold;
    line-height: 1.2;
    overflow: hidden;

    &--small {
      font-size: 18px;
    }
  }

  &__caption {
    display: none;
    flex-grow: 1;
    margin-top: 10px;
    color: $grey;
    font-weight: $regular;
    line-height: 1.18;
  }

  &__price {
    margin-top: 10px;
    color: $second;
    font-weight: $semibold;
    font-size: 34px;
    line-height: 1.2;

    b {
      margin-right: 5px;
      color: $grey;
      font-weight: $regular;
      font-size: 16px;
    }

    i {
      margin-left: 5px;
      font-style: normal;
      font-weight: $regular;
      font-size: 24px;
    }
  }

  &__button {
    margin-top: 10px;
  }
}
</style>
