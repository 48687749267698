<template lang="pug">
  section.services
    header.services__header(
      v-if='category.title'
    )
    .services__body
      ul.services__list.grid.is-columns(
        v-if='category.list.length'
      )
        li.services__item.col-4(
          v-for='id in category.list'
          :key='id'
        )
          Service(
            :id='id'
          )
</template>

<script>
import Service from "@/components/Service.vue";

export default {
  props: {
    category: Object
  },
  components: { Service }
};
</script>

<style lang="scss">
.services {
  width: 904px;

  &__header {
  }

  &__title {
    color: $white;
    font-weight: $semibold;
    line-height: 1.23;
  }

  &__body {
    margin-top: 14px;
  }

  &__list {
  }

  &__item {
  }
}
</style>
