<template lang="pug">
  .section
    Services(
      :category='category'
      :key='category.id'
      v-for='category in categories'
    )
</template>

<script>
import { mapGetters } from "vuex";

import Services from "@/components/Services.vue";

export default {
  computed: {
    ...mapGetters({
      categories: "services/categories"
    })
  },
  components: {
    Services
  }
};
</script>
